import { Plc } from './../../../../domains/plc';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { PlcType } from 'src/app/enumerations/plc-type.enum';
import { SiemensPlcTransportType } from 'src/app/enumerations/siemens-plc-transport-type.enum';

@Component({
  selector: 'app-update-plc',
  templateUrl: './update-plc.component.html',
  styleUrls: ['./update-plc.component.scss']
})
export class UpdatePlcComponent implements OnInit {

  plc: Plc;
  updatePlcForm;
  selectedPlcType = undefined;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.updatePlcForm = this.formBuilder.group({
      id: undefined,
      name: ['', Validators.required],
      plcType: [{ value: '', disabled: true }, Validators.required],
      plcObject: undefined
    });
  }

  ngOnInit(): void {
    this.setPlcConfiguration(this.plc.plcType);
    this.updatePlcForm.patchValue(
      this.plc
    );
  }

  onSubmit(form) {
    form = form.getRawValue();
    //Realizo una validación final sobre los datos numéricos para evitar cometer errores en la data.
    if ((form.plcObject.hasOwnProperty('timeOut')) && (form.plcObject.timeOut == '' || form.plcObject.timeOut == null)) {
      delete form.plcObject.timeOut
    }
    if (form.plcObject.cycleTime == '' || form.plcObject.cycleTime == null) {
      delete form.plcObject.cycleTime
    }
    this.activeModal.close(form);
  }

  setPlcConfiguration(plcType) {
    switch (plcType) {
      case PlcType.SIEMENS:
        this.updatePlcForm.controls.plcObject = this.formBuilder.group({
          id: undefined,
          transport: SiemensPlcTransportType.ETHERNET,
          address: ['', [Validators.required, Validators.pattern("(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)")]],
          port: ['', Validators.required],
          rack: ['', Validators.required],
          slot: ['', Validators.required],
          timeOut: '',
          cycleTime: ['', Validators.min(500)],
          type: ['', Validators.required],
          autoReconnect: [false, Validators.required]
        })
        break;
      case PlcType.ALLEN_BRADLEY:
        this.updatePlcForm.controls.plcObject = this.formBuilder.group({
          id: undefined,
          address: ['', [Validators.required, Validators.pattern("(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)")]],
          slot: ['', Validators.required],
          cycleTime: ['', Validators.min(500)],
          type: ['', Validators.required],
          autoReconnect: [false, Validators.required]
        })
        break;
    }
    this.EmitEventToForm();
    this.selectedPlcType = plcType;

  }

  EmitEventToForm() {
    this.updatePlcForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

}
