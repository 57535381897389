import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { PlcVariables } from 'src/app/domains/plcVariables';
import { OperationsService } from 'src/app/services/operations.service';

@Component({
  selector: 'app-rest-exportation',
  templateUrl: './rest-exportation.component.html',
  styleUrls: ['./rest-exportation.component.scss']
})
export class RestExportationComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() plcVariables: PlcVariables[];
  @Input() actionObject: any;

  mentionConfig = {
    items: [],
    triggerChar: "@",
    labelKey: 'name'
  }

  showTestHttpRequest = false;
  testHttpRequestInfo: any = {};

  constructor(private formBuilder: FormBuilder, private operationsService: OperationsService) { }

  ngOnInit(): void {
    this.parentForm.controls.actionObject = this.formBuilder.group({
      restVerb: [null, Validators.required],
      url: [null, Validators.required],
      agent: [null, Validators.required],
      headers: null,
      bodyType: null,
      body: { value: null, disabled: true },
    });
    this.mentionConfig.items = this.plcVariables;

    //Lógica para reconocimiento de acción de creación o actualización. En este caso se ejecuta en caso de UPDATE!
    if (this.actionObject) {
      (this.parentForm.controls.actionObject as FormGroup).addControl('id', new FormControl(undefined));
      this.parentForm.controls.actionObject.patchValue(
        this.actionObject
      );
      if ((this.parentForm.controls.actionObject as FormGroup).controls.bodyType.value != null) {
        this.bodyTypeChange();
      }
    }
  }

  EmitEventToForm() {
    this.parentForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  bodyTypeChange() {
    (this.parentForm.controls.actionObject as FormGroup).controls.body.enable();
    if ((this.parentForm.controls.actionObject as FormGroup).controls.bodyType.value == 'JSON') {
      this.parentForm.controls.actionObject['controls'].body.setValidators(this.jsonValidator());
    } else {
      this.parentForm.controls.actionObject['controls'].body.clearValidators();
    }
    this.parentForm.controls.actionObject['controls'].body.updateValueAndValidity();
    this.EmitEventToForm();
  }

  testRestRequest() {
    this.operationsService.testRestRequest(this.parentForm.getRawValue().actionObject)
      .subscribe(
        (response) => {
          this.testHttpRequestInfo = response;
          this.showTestHttpRequest = true;
        }
      )
  }

  /**
   * Método encargado de validar JSON ormat en el body de las peticiones HTTP.
   */
  jsonValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      //En caso de que el body este vacio o sea null no valido JSON porque por regla de negocio, el body es opcional!
      if (control.value != '' && control.value != null) {
        try {
          /* Creo una copia del dato en la variable jsonToValidate para hacer la validación contra esta variable
          para poder reemplazar los valores de las variales.*/
          let jsonToValidate = control.value;
          //Busco cualquier palabra que empiece por @ (muy probablemente variables!)
          for (let variable of jsonToValidate.matchAll(/\@([^@\W]+)\b/ig)) {
            /*Reemplazo las palabras que empiezan por @ por un 0. Esto es porque en el caso particular de TOT
            una variable puede ser cualquier tipo de dato, así que para validar el JSON simplemente simulo
            que la variable es un número en este caso 0. Si no fueran variables, tendría que validarla sobre "" (string) */
            jsonToValidate = jsonToValidate.replace(new RegExp(variable[0], 'g'), 0)
          }
          JSON.parse(jsonToValidate);
        } catch (e) {
          return { jsonInvalid: true };
        }
      }
      return null;
    };
  }

  closeHttpResponseToast() {
    this.showTestHttpRequest = false;
  }
}
