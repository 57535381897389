import { Plc } from './../../../../domains/plc';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-tag',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss']
})
export class CreateTagComponent implements OnInit {

  createTagForm;
  plc: Plc;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.createTagForm = this.formBuilder.group({
      name: ['', Validators.required],
      tag: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit(form) {
    this.activeModal.close(form);
  }

}
