<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-trash-alt"></i>&nbsp; Elminar Variable/Tag!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-warning" role="alert">
        Esta acción, eliminará la variable/tag: <strong>{{tag.name}}</strong> del PLC junto con todas sus
        configuraciones asociadas. En caso de que el PLC esté conectado, será desconectado y enlazado nuevamente!
        <p class="mb-0 mt-2 text-justify" style="color: #856404">
            <i class="fas fa-exclamation-triangle mr-1"></i>
            Si el PLC cuenta con operaciones que involucren el uso de esta variable, al ser eliminada, las operaciones
            deberán ser gestionadas manualmente, de lo contrario dicha operación seguirá siendo ejecutada pero el valor
            de la variable no será reemplazado!
        </p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="activeModal.close()">Eliminar</button>
</div>