import { DataBases } from './../../../../domains/dataBases';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { DataBasesService } from 'src/app/services/data-bases.service';

@Component({
  selector: 'app-update-db',
  templateUrl: './update-db.component.html',
  styleUrls: ['./update-db.component.scss']
})
export class UpdateDbComponent implements OnInit {

  updateDbForm;
  dbConnection: DataBases;
  selectedDbType = undefined;
  testConnection: boolean = false;
  testResult: any;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder,
    private dataBaseService: DataBasesService) {
    this.updateDbForm = this.formBuilder.group({
      id: undefined,
      name: '',
      host: ['', Validators.required],
      port: ['', [Validators.min(0), Validators.max(65536)]],
      user: ['', Validators.required],
      password: ['', Validators.required],
      database: ['', Validators.required],
      type: ['', Validators.required],
      version: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.selectedDbType = this.dbConnection.type;
    this.updateDbForm.patchValue(
      this.dbConnection
    );
  }

  onSubmit(form) {
    this.activeModal.close(form.getRawValue());
  }

  testDbConnection() {
    this.dataBaseService.testDataBaseConnection(this.updateDbForm.value)
      .subscribe(
        (msg: any) => {
          this.testResult = { toastr: msg.toastr, class: 'text-success' }
          this.testConnection = true;
        },
        (error) => {
          this.testResult = { toastr: error.error.toastr, class: 'text-danger' }
        }
      );
  }

  resetConnectionFlag() {
    this.testConnection = false;
  }

  dbTypeChange(event) {
    this.selectedDbType = event.target.value;
  }

  EmitEventToForm() {
    this.updateDbForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

}
