<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-trash-alt"></i>&nbsp; Elminar PLC!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-warning" role="alert">
        Esta acción, eliminará la configuración del PLC: <strong>{{plcName}}</strong>. También eliminará sus
        Variables/Tags y sus respectivas configuraciones!!. El PLC será desconectado y desenlazado!
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="activeModal.close()">Eliminar</button>
</div>