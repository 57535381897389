import { PlcOperation } from './../../../../../domains/PlcOperation';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-operation',
  templateUrl: './delete-operation.component.html',
  styleUrls: ['./delete-operation.component.scss']
})
export class DeleteOperationComponent implements OnInit {

  operation: PlcOperation;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
