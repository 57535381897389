<hr>
<div [formGroup]="parentForm">
    <div formGroupName="actionObject">
        <div class="form-row">
            <div class="form-group col-md">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Método: </span>
                    </div>
                    <select class="custom-select" formControlName="restVerb" (change)="EmitEventToForm()">
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="PUT">PUT</option>
                        <option value="DELETE">DELETE</option>
                    </select>
                </div>
            </div>
            <div class="form-group col-md">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Agente: </span>
                    </div>
                    <select class="custom-select" formControlName="agent" (change)="EmitEventToForm()">
                        <option value="HTTP">HTTP</option>
                        <option value="HTTPS">HTTPS</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group mb-2">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-link"></i></span>
                </div>
                <input type="text" class="form-control" formControlName="url"
                    placeholder="Dirección URL -> Ejm: api.google:8081/v1/sendData" (keyup)="EmitEventToForm()"
                    [mentionConfig]="mentionConfig" placement="right" [ngbPopover]="urlInfo" popoverTitle="URL"
                    popoverClass="infoQueryPopOver" container="body">
            </div>
            <label class="mb-0">
                <small>
                    {{parentForm.controls.actionObject['controls'].agent?.value == null 
                        ? '' 
                        : parentForm.controls.actionObject['controls'].agent?.value == 'HTTP' 
                            ? 'http://' 
                            : 'https://'}}
                    {{parentForm.controls.actionObject['controls'].url?.value}}
                </small>
            </label>
        </div>
        <div class="form-group"
            *ngIf="(parentForm.controls.actionObject['controls'].restVerb?.value == 'POST') || (parentForm.controls.actionObject['controls'].restVerb?.value == 'PUT')">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Tipo Body: </span>
                </div>
                <select class="custom-select" formControlName="bodyType" (change)="bodyTypeChange()">
                    <option value="JSON">JSON</option>
                    <option value="TEXT">TEXT</option>
                </select>
            </div>
        </div>
        <div class="form-group"
            *ngIf="(parentForm.controls.actionObject['controls'].restVerb?.value == 'POST') || (parentForm.controls.actionObject['controls'].restVerb?.value == 'PUT')">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 100px;">Body: </span>
                </div>
                <textarea class="form-control" formControlName="body" rows="3" placement="right" [ngbPopover]="bodyInfo"
                    popoverTitle="Body" popoverClass="infoBodyPopOver" container="body" [mentionConfig]="mentionConfig"
                    placeholder="El body de la petición HTTP es un campo opcional!"
                    (keyup)="EmitEventToForm()"></textarea>
            </div>
            <div *ngIf="parentForm.controls.actionObject['controls'].body.invalid" class="text-danger">
                <div *ngIf="parentForm.controls.actionObject['controls'].body.errors.jsonInvalid">
                    El body ingresado no cumple con el formato JSON correcto!!
                </div>
            </div>
        </div>
        <div class="text-center">
            <button *ngIf="(parentForm.controls.actionObject['controls'].restVerb?.value != null) &&
                        (parentForm.controls.actionObject['controls'].agent?.value != null)" type="button"
                class="btn btn-info" (click)="testRestRequest()" placement="right" [ngbPopover]="testRequestInfo"
                popoverTitle="Probar petición RESTFUL" popoverClass="infoHttpTest" container="body"
                triggers="mouseenter:mouseleave">
                <i class="fas fa-bolt mr-2"></i>
                Probar petición {{parentForm.controls.actionObject['controls'].restVerb?.value}}</button>
        </div>
        <ngb-toast *ngIf="showTestHttpRequest" [header]="(testHttpRequestInfo.status == 999 || testHttpRequestInfo.status >= 400) 
                ? ('Error! → HTTP STATUS_CODE(' + testHttpRequestInfo.status + ')') 
                : ('Correcto! → HTTP STATUS_CODE(' + testHttpRequestInfo.status + ')')" [autohide]="false"
            style="margin: auto; margin-top: 10px;" (hide)="closeHttpResponseToast()" class="text-justify">
            <div [ngClass]="(testHttpRequestInfo.status == 999 || testHttpRequestInfo.status >= 400) 
                ? 'text-danger' 
                : 'text-success'" style="display: grid;">
                <label class="mb-0" *ngIf="testHttpRequestInfo.error"><strong>Error:</strong>
                    {{testHttpRequestInfo.error}}</label>
                <label class="mb-0" *ngIf="testHttpRequestInfo.message"><strong>Desc:
                    </strong>{{testHttpRequestInfo.message}}</label>
                <label class="mb-0" *ngIf="testHttpRequestInfo.path"><strong>Path:
                    </strong>{{testHttpRequestInfo.path}}</label>
            </div>
        </ngb-toast>
    </div>
</div>
<!-- Esta es la información acerca de la URL para REST -->
<ng-template #urlInfo>
    <div class=" text-justify">
        <p>Este campo, le permite especificar la URL del servicio web RESTFUL que se llamará al momento de ejecutar la
            acción que está configurando.</p>
        <p>
            Dentro de la URL, es posible tener path asociados a variables, por lo que puede construirlas
            anteponiento la <code>@</code> para autocompletado:
        </p>
        <div class="bd-callout bd-callout-warning">
            <h6>Paths</h6>
            <em>
                http://[host]:[port]/api/v1/@variable/
            </em>
        </div>
        <p>Asi mismo, si requiere enviar datos de las variables leídas por el PLC a través del QueyString de la URL, es
            posible anteponiento la <code>@</code> para autocompletado:</p>
        <div class="bd-callout bd-callout-warning">
            <h6>QueryString</h6>
            <em>
                http://[host]:[port]/api/v1/@variable?plcConnected=@variable1&currentOperation=@variable2
            </em>
        </div>
    </div>
</ng-template>

<!-- Esta es la información acerca del body de las peticiones REST -->
<ng-template #bodyInfo>
    <div class="text-justify">
        <p>Este campo, le permite especificar el body que será enviado en la petición POST/PUT HTTP de la acción de la
            operación que está configurando en este momento. Si desea ampliar la información acerca del body de una
            petición HTTP, de click al siguiente link: <a href="https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html"
                target="_blank">w3org</a></p>
        <p>
            Dentro del body, es posible tener información asociada a variables, por lo que puede utilizarlas
            anteponiento la <code>@</code> para autocompletado:
        </p>
        <div class="bd-callout bd-callout-warning">
            <h6>Http Body - JSON</h6>
            <pre class="mb-0">
                {{{"plcStatus": "@Variable1", "sensorValue":"@Variable2", "engines":[{"engine1":"@Variable3"},{"engine2":"@Variable4"}]} | json}}
            </pre>
        </div>
        <p><strong>NOTA:</strong> El uso de variables (@variable) en el body no necesita ser encerrado con "". TOT se
            encargará de convertir el valor de la variable en el formato correcto para JSON pero el resto del formato si
            debe de ser garantizado por usted.</p>
        <p>Para más información sobre el formato JSON, refierase al siguiente link: <a
                href="https://www.json.org/json-en.html" target="_blank">JSON ORG</a></p>
    </div>
</ng-template>

<!-- Esta es la información acerca de testear las peticiones REST -->
<ng-template #testRequestInfo>
    <div class="text-justify">
        <p>Esta acción le permite probar si la configuración que esta realizando para la operación por medio de llamados
            web-services RESTFUL esta correcta o no!</p>
        <p><strong>NOTA: </strong>La acción de probar la petición HTTP/S no reemplaza las posibles variables definidas
            en la URL o en el BODY de la petición (@variable). Esta funcionalidad está más orientada a probar la
            comunicación y el formato de su petición. Se recomienda usar datos de prueba que reemplacen el uso de
            @Variables mientras prueba la petición, y luego reemplazar los valores de prueba por @variables nuevamente!
        </p>
    </div>
</ng-template>