import { Plc } from './../domains/plc';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private pathPlc = [AppConfig.api.URL, 'plc'].join('/');

  constructor(private http: HttpClient) { }

  getAllPlcs() {
    return this.http.get(`${this.pathPlc}/findAllPlcs`);
  }

  cratePlc(plc: Plc) {
    return this.http.post(`${this.pathPlc}/cratePlc`, plc);
  }

  deletePlc(id) {
    return this.http.delete(`${this.pathPlc}/deletePlc?id=${id}`);
  }

  updatePlc(plc: Plc) {
    return this.http.put(`${this.pathPlc}/updatePlc`, plc);
  }

  connectPlc(plc: Plc) {
    return this.http.post(`${this.pathPlc}/connectPlc`, plc);
  }

  disconnectPlc(plc: Plc) {
    return this.http.post(`${this.pathPlc}/disconnectPlc`, plc);
  }

  resetPlcConnection(id: number, plcType: string, force: boolean) {
    return this.http.get(`${this.pathPlc}/resetPlcConnection?plcId=${id}&plcType=${plcType}&force=${force}`);
  }

  getAllLogsByPlc(plcId: number) {
    return this.http.get(`${this.pathPlc}/findAllLogsByPlc?plcId=${plcId}`);
  }

  deleteAllLogsByPlc(plcId: number) {
    return this.http.delete(`${this.pathPlc}/deleteAllLogsByPlc?plcId=${plcId}`);
  }
}
