<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-microchip"></i>&nbsp; Agregar PLC!</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="createPlcForm" (ngSubmit)="onSubmit(createPlcForm.value)">
    <div class="modal-body">
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Nombre del PLC: </span>
                </div>
                <input required type="text" class="form-control" formControlName="name" aria-describedby="basic-addon3">
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 144px;">Tipo del PLC: </span>
                </div>
                <select class="custom-select" formControlName="plcType" (change)="plcTypeChange($event)">
                    <option value="SIEMENS">SIEMENS</option>
                    <option disabled value="ALLEN_BRADLEY">ALLEN_BRADLEY</option>
                </select>
            </div>
        </div>
        <ng-container *ngIf="selectedPlcType == undefined; else plcForms"></ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
        <button type="submit" ngbAutofocus class="btn btn-success" [disabled]="createPlcForm.invalid">Guardar</button>
    </div>
</form>


<ng-template #plcForms>
    <div *ngIf="selectedPlcType === 'SIEMENS'; then siemensConfig; else abConfig"></div>
</ng-template>
<ng-template #siemensConfig>
    <div [formGroup]="createPlcForm" (keyup)="EmitEventToForm()">
        <div formGroupName="plcObject">
            <div class="form-row">
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-globe-americas"></i></span>
                        </div>
                        <input required type="text" class="form-control" formControlName="address"
                            placeholder="Ingrese la dirección IP">
                    </div>
                </div>
                <div class="form-group col-md">
                    <label class="reconnectLabel mr-2" placement="right" [ngbPopover]="reconnexionContentInfo"
                        popoverTitle="Reconexión automática" popoverClass="customSizePopover" container="body"> <i
                            class="fas fa-plug mr-2"></i>Reconexión automática: </label>
                    <input type="checkbox" formControlName="autoReconnect" (change)="EmitEventToForm()">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-ethernet"></i></span>
                        </div>
                        <input type="number" class="form-control" formControlName="port" placeholder="Puerto">
                    </div>
                </div>
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-server"></i></span>
                        </div>
                        <input type="number" class="form-control" formControlName="rack" placeholder="Rack">
                    </div>
                </div>
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-hdd"></i></span>
                        </div>
                        <input type="number" class="form-control" formControlName="slot" placeholder="Slot">
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-sync-alt"></i></span>
                        </div>
                        <input type="number" class="form-control" formControlName="cycleTime" placeholder="Tiempo Ciclo"
                            id="cycleTime">
                    </div>
                    <label for="cycleTime" class="mb-0"><small>Valor por defecto: 500 ms</small></label>
                </div>
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-clock"></i></span>
                        </div>
                        <input type="number" class="form-control" formControlName="timeOut" placeholder="TimeOut"
                            id="timeOut">
                    </div>
                    <label for="timeOut" class="mb-0"><small>Valor por defecto: 1500 ms</small></label>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" style="width: 144px;">Tipo SIEMENS: </span>
                    </div>
                    <select class="custom-select" formControlName="type" (change)="EmitEventToForm()">
                        <option value="S7-300">S7-300</option>
                        <option value="S7-400">S7-400</option>
                        <option value="S7-1200">S7-1200</option>
                        <option value="S7-1500">S7-1500</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #abConfig>
    <div [formGroup]="createPlcForm" (keyup)="EmitEventToForm()">
        <div formGroupName="plcObject">
            <div class="form-row">
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-globe-americas"></i></span>
                        </div>
                        <input type="text" class="form-control" formControlName="address"
                            placeholder="Ingrese la dirección IP">
                    </div>
                </div>
                <div class="form-group col-md">
                    <label class="reconnectLabel mr-2" placement="right" [ngbPopover]="reconnexionContentInfo"
                        popoverTitle="Reconexión automática" popoverClass="customSizePopover" container="body"> <i
                            class="fas fa-plug mr-2"></i>Reconexión automática: </label>
                    <input type="checkbox" formControlName="autoReconnect" (change)="EmitEventToForm()">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-hdd"></i></span>
                        </div>
                        <input type="number" class="form-control" formControlName="slot" placeholder="Slot">
                    </div>
                </div>
                <div class="form-group col-md">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-sync-alt"></i></span>
                        </div>
                        <input type="number" class="form-control" formControlName="cycleTime" placeholder="Tiempo Ciclo"
                            id="cycleTime">
                    </div>
                    <label for="cycleTime" class="mb-0"><small>Valor por defecto: 1000 ms</small></label>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Tipo ALLEN_BRADLEY: </span>
                    </div>
                    <select class="custom-select" formControlName="type" (change)="EmitEventToForm()">
                        <option value="COMPACTLOGIX">COMPACTLOGIX</option>
                        <option value="CONTROLLOGIX">CONTROLLOGIX</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Esta es la información acerca de la reconexión de los PLC -->
<ng-template #reconnexionContentInfo>
    <div class="text-justify">
        <p>Esta opción, le asegura que el PLC siempre estará conectado a TOT-COMS.</p>
        <p>Cuando exista un error en la comunicación ya establecida (Error de red, desconexión física u
            otro), TOT-COMS intentará reconectar el PLC automáticamente hasta que la conexión sea restablecida
            satisfactoriamente.</p>
        <p>Note que cuando un PLC es creado por primera vez, si esta opción
            está habilitada, el PLC se intentará conectar automáticamente una vez se haya creado. Es recomendable que
            solo habilite la reconexión automática cuando el PLC este completamente configurado y listo para captura de
            datos en 'producción'.</p>
        <p class="mb-0">Esta opción, también contempla la conexión automática del PLC incluso cuando TOT-COMS sea
            ejecutado por primera vez!</p>
    </div>
</ng-template>