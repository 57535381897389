import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Plc } from 'src/app/domains/plc';
import { webSocket } from 'rxjs/webSocket'
import { AppConfig } from 'src/app/app.config';


@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit, OnDestroy {

  plc: Plc;
  variables: any = {};
  private plcsWebSocket;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initTagsWebSocket();
  }

  //Instancio la conexión con el webSocket de PLCS para manejar actualización del monitor de variables
  private initTagsWebSocket() {
    this.plcsWebSocket = webSocket([AppConfig.ws.URL, 'tags', this.plc.id].join('/'))
      .subscribe(
        (msg: any) => {
          if (this.plc.id == msg.id) {
            this.variables = msg.tags;
          }
        },
        err => this.initTagsWebSocket(),
        () => console.log('Cerrando webSocket en topic: tags/' + this.plc.id)
      );
  }

  ngOnDestroy(): void {
    this.plcsWebSocket.complete()
  }

}
