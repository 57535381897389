import { PlcVariables } from './../../../../domains/plcVariables';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-update-tag',
  templateUrl: './update-tag.component.html',
  styleUrls: ['./update-tag.component.scss']
})
export class UpdateTagComponent implements OnInit {

  tag: PlcVariables;
  updateTagForm;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
    this.updateTagForm = this.formBuilder.group({
      id: undefined,
      name: ['', Validators.required],
      tag: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.updateTagForm.patchValue(
      this.tag
    );
  }

  onSubmit(form) {
    this.activeModal.close(form.getRawValue());
  }

}
