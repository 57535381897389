import { DashboardService } from 'src/app/services/dashboard.service';
import { PlcLog } from './../../../domains/plcLog';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Plc } from 'src/app/domains/plc';

@Component({
  selector: 'app-plc-logs',
  templateUrl: './plc-logs.component.html',
  styleUrls: ['./plc-logs.component.scss']
})
export class PlcLogsComponent implements OnInit {

  plc: Plc;
  plcLogs: PlcLog[] = [];

  constructor(public activeModal: NgbActiveModal, private dashBoardService: DashboardService) {
  }

  ngOnInit(): void {
    this.dashBoardService.getAllLogsByPlc(this.plc.id)
      .subscribe((logs: PlcLog[]) => {
        this.plcLogs = logs;
      });
  }

  deleteAllPlcLogs() {
    this.dashBoardService.deleteAllLogsByPlc(this.plc.id).subscribe(
      () => { },
      () => { },
      () => { this.activeModal.dismiss() }
    );
  }

}
