import { Plc } from './../domains/plc';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { PlcOperation } from '../domains/PlcOperation';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  private pathPlc = [AppConfig.api.URL, 'plc', 'operations'].join('/');
  private messageSource = new BehaviorSubject(undefined);
  currentPlc = this.messageSource.asObservable();

  constructor(private http: HttpClient) { }

  setCurrentPlc(plc: Plc) {
    this.messageSource.next(plc)
  }

  crateOperation(plc: Plc, operation: PlcOperation) {
    return this.http.post(`${this.pathPlc}/crateOperation?plcType=${plc.plcType}`, operation);
  }

  getPlcOperations(plc: Plc) {
    return this.http.get(`${this.pathPlc}/getPlcOperations?plcId=${plc.id}`);
  }

  deleteOperation(plc: Plc, operation: PlcOperation) {
    return this.http.post(`${this.pathPlc}/deleteOperation?plcType=${plc.plcType}`, operation);
  }

  updateOperation(plc: Plc, operation: PlcOperation) {
    return this.http.put(`${this.pathPlc}/updateOperation?plcType=${plc.plcType}`, operation);
  }

  testRestRequest(httpActionObject: any) {
    return this.http.post(`${this.pathPlc}/testRestRequest`, httpActionObject);
  }
}
