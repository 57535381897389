import { DataBases } from './../../../../domains/dataBases';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-db',
  templateUrl: './delete-db.component.html',
  styleUrls: ['./delete-db.component.scss']
})
export class DeleteDbComponent implements OnInit {

  dbConnectionName: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
