import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { NbToastrService, NbToastrConfig } from '@nebular/theme';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    notifyConfig: any = {
        destroyByClick: true,
        duration: 5000
    }
    constructor(private notify: NbToastrService) { };

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body && event.body.toastr) {
                        //Configuro el toastr
                        this.notifyConfig.icon = 'checkmark-outline'
                        this.notify.success(event.body.toastr, 'Correcto!', this.notifyConfig);
                    }
                }
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.error) {
                        if (err.status == 402) {
                            this.notifyConfig.icon = 'close-outline';
                            this.notify.danger('La licencia para TOT es invalida o expiró, actualice la licencia!', 'LICENCIA INVALIDA!', this.notifyConfig);
                        } else if (err.error.toastr) {
                            if (err.status === 418) {
                                this.notifyConfig.icon = 'alert-triangle-outline'
                                this.notify.warning(err.error.toastr, 'Advertencia!', this.notifyConfig);
                            } else {
                                this.notifyConfig.icon = 'close-outline';
                                this.notify.danger(err.error.toastr, 'Error!', this.notifyConfig);
                            }
                        } else if (err.status === 401) {
                            //Considerar manejo de autenticación más adelante
                        }
                        else {
                            //Configuro el toastr
                            this.notifyConfig.icon = 'close-outline';
                            this.notify.danger('Posible Error de conexión y configuración de TOT-COMS, ' +
                                'comuníquese con el personal de soporte de TI!', 'Error!', this.notifyConfig);
                        }
                    }
                }
                return throwError(err);
            })
        );
    }
}