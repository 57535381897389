export const AppConfig = {
    api: {
        // URL: 'http://localhost:3000/api/v1/tot',
        URL: '/api/v1/tot'

    },
    ws: {
        // URL: 'ws://localhost:3000'
        URL: (window.location.protocol.startsWith('https') ? 'wss://' : 'ws://') + window.location.host
    }
};
