<div class="modal-header">
    <h4 class="modal-title"> <i class="fas fa-tag"></i>&nbsp; Agregar Variable/Tag</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="createTagForm" (ngSubmit)="onSubmit(createTagForm.value)">
    <div class="modal-body">
        <div class="alert alert-light pt-0 pb-0" role="alert">
            La variable/tag, será agregada para lectura/escritura al PLC: <strong>{{plc.name}}</strong>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">Nombre de V/T: </span>
                </div>
                <input required type="text" class="form-control" formControlName="name" id="tagName">
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="width: 140px;">Valor de V/T: </span>
                </div>
                <input required type="text" class="form-control" formControlName="tag"
                    placeholder="Espacio de memoria a leer/escribir">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cerrar</button>
        <button type="submit" ngbAutofocus class="btn btn-success" [disabled]="createTagForm.invalid">Guardar</button>
    </div>
</form>