import { PlcVariables } from './../../../../domains/plcVariables';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-tag',
  templateUrl: './delete-tag.component.html'
})
export class DeleteTagComponent implements OnInit {

  tag: PlcVariables;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
