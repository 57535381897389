import { DataBasesService } from './../../../../services/data-bases.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-db',
  templateUrl: './create-db.component.html',
  styleUrls: ['./create-db.component.scss']
})
export class CreateDbComponent implements OnInit {

  createDbForm;
  selectedDbType = undefined;
  testConnection: boolean = false;
  testResult: any;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder,
    private dataBaseService: DataBasesService) {
    this.createDbForm = this.formBuilder.group({
      name: '',
      host: ['', Validators.required],
      port: ['', [Validators.min(0), Validators.max(65536)]],
      user: ['', Validators.required],
      password: ['', Validators.required],
      database: ['', Validators.required],
      type: ['', Validators.required],
      version: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit(form) {
    this.activeModal.close(form);
  }

  testDbConnection() {
    this.dataBaseService.testDataBaseConnection(this.createDbForm.value)
      .subscribe(
        (msg: any) => {
          this.testResult = { toastr: msg.toastr, class: 'text-success' }
          this.testConnection = true;
        },
        (error) => {
          this.testResult = { toastr: error.error.toastr, class: 'text-danger' }
        }
      );
  }

  dbTypeChange(event) {
    this.selectedDbType = event.target.value;
  }

  resetConnectionFlag() {
    this.testConnection = false;
  }

  EmitEventToForm() {
    this.createDbForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

}
