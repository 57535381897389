import { Component } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'app-root',
  template: '<ng-progress></ng-progress><router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerFontPack('font-awesome');
  }

}
