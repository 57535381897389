import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NbThemeModule, NbSidebarModule, NbMenuModule, NbToastrModule } from '@nebular/theme';
import { CreatePlcComponent } from './views/dashboard/forms/create-plc/create-plc.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpInterceptorService } from './app-interceptor.service';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { DeletePlcComponent } from './views/dashboard/forms/delete-plc/delete-plc.component';
import { UpdatePlcComponent } from './views/dashboard/forms/update-plc/update-plc.component';
import { CreateTagComponent } from './views/dashboard/forms/create-tag/create-tag.component';
import { DeleteTagComponent } from './views/dashboard/forms/delete-tag/delete-tag.component';
import { UpdateTagComponent } from './views/dashboard/forms/update-tag/update-tag.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlcLogsComponent } from './views/dashboard/plc-logs/plc-logs.component';
import { MonitorComponent } from './views/dashboard/monitor/monitor.component';
import { CreateDbComponent } from './views/configurations/forms/create-db/create-db.component';
import { DeleteDbComponent } from './views/configurations/forms/delete-db/delete-db.component';
import { UpdateDbComponent } from './views/configurations/forms/update-db/update-db.component';
import { CreateOperationComponent } from './views/dashboard/operations/forms/create-operation/create-operation.component';
import { DatabaseExportationComponent } from './views/dashboard/operations/operationsActions/database-exportation/database-exportation.component';
import { MentionModule } from 'angular-mentions';
import { DeleteOperationComponent } from './views/dashboard/operations/forms/delete-operation/delete-operation.component';
import { UpdateOperationComponent } from './views/dashboard/operations/forms/update-operation/update-operation.component';
import { RestExportationComponent } from './views/dashboard/operations/operationsActions/rest-exportation/rest-exportation.component';

@NgModule({
  declarations: [
    AppComponent,
    CreatePlcComponent,
    DeletePlcComponent,
    UpdatePlcComponent,
    CreateTagComponent,
    DeleteTagComponent,
    UpdateTagComponent,
    PlcLogsComponent,
    MonitorComponent,
    CreateDbComponent,
    DeleteDbComponent,
    UpdateDbComponent,
    CreateOperationComponent,
    DatabaseExportationComponent,
    DeleteOperationComponent,
    UpdateOperationComponent,
    RestExportationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgProgressModule,
    NgProgressHttpModule,
    NbThemeModule.forRoot(),
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    MentionModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
